import { FC } from 'react';
import { Form } from 'antd';
import RichTextEditor, {
  RichTextEditorProps,
} from 'src/components/RichTextEditor';
import { FormBuilderFieldTextEditor } from 'src/types/formBuilder';
import { UploadFileApi } from 'src/types';

interface FieldTextEditorProps {
  id?: string;
  value?: RichTextEditorProps['value'];
  data: FormBuilderFieldTextEditor;
  disabled: boolean;
  onChange?: RichTextEditorProps['onChange'];
  onTextImageUpload?: (upload: UploadFileApi) => Promise<void | string>;
}

const FieldTextEditor: FC<FieldTextEditorProps> = ({
  id,
  value,
  data,
  disabled,
  onChange,
  onTextImageUpload,
}) => {
  const form = Form.useFormInstance();

  return (
    <RichTextEditor
      id={id}
      value={value}
      className={data.scroll ? 'h-small' : 'min-h-small'}
      scrollingContainer={data.scroll ? '.ql-editor' : undefined}
      readonly={disabled}
      placeholder={data.placeholder}
      onChange={onChange}
      onImageUploader={
        onTextImageUpload
          ? onTextImageUpload
          : async (upload) => {
              const currentUploads = form.getFieldValue('uploads');
              form.setFieldsValue({
                uploads: currentUploads
                  ? [...currentUploads, upload]
                  : [upload],
              });
            }
      }
    />
  );
};

export default FieldTextEditor;
