import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { debounceAsync } from 'src/utils/debounce';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import ENVIRONMENT from 'src/utils/environments';
import type { RootState } from 'src/store';
import type {
  FormBuilderData,
  FormBuilderFormData,
} from 'src/types/formBuilder';
import type { UploadFileApi, UserResponse } from 'src/types';
import listFormsData from './listForms.json';
import { requestFixedFields } from './RequestForm';
import { getFormData } from '../../components/FormBuilder/buttons/FormSubmitButton';

const listForms = listFormsData as FormBuilderData[];

const debounceUpdate = debounceAsync((onUpdate: () => Promise<void>) => {
  return onUpdate();
}, 600);

const parseFormBuilderAnswers = (
  answers: Record<string, any>,
  data: FormBuilderFormData[]
) => {
  return data.reduce((acc, item) => {
    if (item.type === 'formGroup') {
      const formGroup = listForms.find((form) => form.id === item.formId);

      if (formGroup?.form) {
        acc.push({
          ...item,
          fields: parseFormBuilderAnswers(answers, formGroup.form),
        });
      }
    }

    if ('name' in item) {
      let answer = answers[item.name];

      if (item.type === 'upload' && Array.isArray(answer)) {
        answer = answer
          .filter(
            (file: UploadFileApi) => file.response && !file.response.stated
          )
          .map((file: UploadFileApi) => file.response?.uuid);
      }

      if (item.type === 'textEditor' && answer) {
        answer = answer.replaceAll(
          `src="${ENVIRONMENT.REACT_APP_UPLOADS_PATH.replace(
            'uploads',
            'tmp'
          )}/`,
          `src="${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/`
        );
      }

      acc.push({ ...item, answer });
    }

    return acc;
  }, [] as FormBuilderFormData[]);
};

interface UseRequestFormActionsOptions {
  form: FormInstance;
  formBuilder?: FormBuilderData;
  ignoreUploadsOnSubmit?: boolean;
  onDiscard?: () => Promise<void>;
  onDraft?: (values: any) => Promise<Record<string, any>>;
  onSubmit?: (values: any) => Promise<Record<string, any>>;
  onUpdate?: (values: any) => Promise<Record<string, any>>;
}

const useRequestFormActions = ({
  form,
  formBuilder,
  ignoreUploadsOnSubmit,
  onDiscard,
  onDraft,
  onSubmit,
  onUpdate,
}: UseRequestFormActionsOptions) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const project = useSelector(
    (globalState: RootState) => globalState.projectRetainer.data
  );

  const [saving, setSaving] = useState(false);

  const parseDataToSave = (values: Record<string, any>) => {
    const newValues: Record<string, any> = {
      user_id: user.uuid,
      project_id: project?.uuid,
    };

    if (values) {
      Object.values(requestFixedFields).forEach((field) => {
        newValues[field.name] = values[field.name];

        delete values[field.name];
      });
    }

    if (formBuilder && values) {
      newValues.description = JSON.stringify({
        ...formBuilder,
        form:
          formBuilder.form && parseFormBuilderAnswers(values, formBuilder.form),
        wizardForm:
          formBuilder.wizardForm &&
          formBuilder.wizardForm.map((item) => ({
            ...item,
            form: parseFormBuilderAnswers(values, item.form),
          })),
      });

      newValues.title = formBuilder.name;
    }

    if ('target_date' in newValues) {
      newValues.target_date =
        newValues.target_date &&
        dayjs(newValues.target_date).format('YYYY-MM-DD');
    }

    if ('uploads' in newValues && Array.isArray(newValues.uploads)) {
      newValues.uploads = newValues.uploads
        .filter((file: UploadFileApi) => file.response)
        .map((file: UploadFileApi) => file.response?.uuid);
    }

    newValues.subject = newValues.subject || 'Draft';

    newValues.kickoff_call = newValues.kickoff_call ?? null;

    newValues.skip_estimate_approval =
      newValues.skip_estimate_approval ?? false;

    return newValues;
  };

  const onWrapDraft = async (values: any) => {
    setSaving(true);

    try {
      await onDraft?.({
        ...parseDataToSave(values),
        status: 'draft',
      });
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const onWrapSubmit = async (values: any) => {
    setSaving(true);

    if (ignoreUploadsOnSubmit) {
      delete values.uploads;
    }

    try {
      await onSubmit?.({
        ...parseDataToSave(values),
        status: 'new',
      });
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  const onWrapUpdate = () => {
    if (!onUpdate) return;

    setSaving(true);

    debounceUpdate(() => {
      return getFormData(form)
        .then((values) => {
          delete values.uploads;

          onUpdate(parseDataToSave(values))
            .catch((error) => asyncErrorHandler(error))
            .finally(() => setSaving(false));
        })
        .catch(() => setSaving(false));
    });
  };

  const onWrapDiscard = async () => {
    setSaving(true);

    try {
      await onDiscard?.();
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSaving(false);
    }
  };

  return {
    saving,
    onDiscard: onWrapDiscard,
    onDraft: onWrapDraft,
    onSubmit: onWrapSubmit,
    onUpdate: onWrapUpdate,
  };
};

export default useRequestFormActions;
