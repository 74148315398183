import { FC, ReactNode, useMemo } from 'react';
import { Divider, Form, FormInstance, Typography } from 'antd';
import FormBuilderAnswers from 'src/components/FormBuilder/FormBuilderAnswers';
import FormBuilderWizardForm from 'src/components/FormBuilder/FormBuilderWizardForm';
import FormItem from 'src/components/FormBuilder/FormItem';
import FieldUpload from 'src/components/FormBuilder/fields/FieldUpload';
import RedirectConfirmModel from 'src/components/RedirectConfirmModel';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import {
  FormBuilderData,
  FormBuilderFieldDate,
  FormBuilderFieldInput,
  FormBuilderFieldRadio,
  FormBuilderFieldUpload,
} from 'src/types/formBuilder';
import listFormsData from './listForms.json';

const { Title } = Typography;

const listForms = listFormsData as FormBuilderData[];

interface FixedFields {
  subject: FormBuilderFieldInput;
  deadline: FormBuilderFieldDate;
  skipEstimateApproval: FormBuilderFieldRadio;
  kickOffCall: FormBuilderFieldRadio;
  uploads: FormBuilderFieldUpload;
}

export const requestFixedFields: FixedFields = {
  subject: {
    name: 'subject',
    label: 'Project name',
    type: 'input',
    submitRequired: true,
    placeholder: 'Give your project a name',
  },
  deadline: {
    name: 'target_date',
    label: 'Deadline',
    type: 'date',
    submitRequired: true,
    mode: 'simple',
  },
  skipEstimateApproval: {
    name: 'skip_estimate_approval',
    label: 'Skip estimate approval?',
    type: 'radio',
    mode: 'pill',
    optionalLabel: true,
    options: [
      {
        label:
          "Yes, I'd like to skip the estimate approval and start the project right away.",
        value: true,
      },
    ],
  },
  kickOffCall: {
    name: 'kickoff_call',
    label: 'Would you like to message us or call before we get started?',
    type: 'radio',
    mode: 'pill',
    optionalLabel: true,
    options: [
      {
        label: 'Yes (Message)',
        value: 'message',
      },
      {
        label: 'Yes (Call)',
        value: 'call',
      },
    ],
  },
  uploads: {
    name: 'uploads',
    label: 'Upload any assets related to this project (optional)',
    type: 'upload',
  },
};

interface RequestFormProps {
  form: FormInstance;
  request?: Record<string, any>;
  formBuilder: FormBuilderData;
  footer?: ReactNode;
  header?: boolean;
  confirmBeforeRedirect?: boolean;
  disableForm?: boolean;
  saving?: boolean;
  onFormUpdate?: () => void;
  onFieldUpdate?: (values: any) => Promise<Record<string, any>>;
}

const RequestForm: FC<RequestFormProps> = ({
  form,
  request,
  formBuilder,
  footer,
  header = false,
  confirmBeforeRedirect = false,
  disableForm = false,
  saving,
  onFormUpdate,
  onFieldUpdate,
}) => {
  const formData = useMemo(() => {
    if (!formBuilder.form) return undefined;

    return [
      {
        ...requestFixedFields.subject,
        answer: request?.[requestFixedFields.subject.name],
      },
      ...formBuilder.form,
      {
        ...requestFixedFields.deadline,
        answer: request?.[requestFixedFields.deadline.name],
      },
      {
        ...requestFixedFields.skipEstimateApproval,
        answer: request?.[requestFixedFields.skipEstimateApproval.name],
      },
      {
        ...requestFixedFields.kickOffCall,
        answer: request?.[requestFixedFields.kickOffCall.name],
      },
    ];
  }, [formBuilder.form, request]);

  return (
    <div className="pb-2 sm:pt-2">
      {confirmBeforeRedirect && !saving && <RedirectConfirmModel form={form} />}

      {header && (
        <Title level={5} className="!mb-0">
          {formBuilder.name}
        </Title>
      )}

      {header && <Divider className="!mb-4 !mt-2" />}

      <Form
        form={form}
        layout="vertical"
        labelCol={{ style: { fontWeight: '600' } }}
        colon={false}
        requiredMark={false}
        onValuesChange={(changedValues) => {
          const keys = Object.keys(changedValues);

          if (keys.length > 1 || keys[0] !== 'uploads') {
            onFormUpdate?.();
          }
        }}
      >
        {formBuilder?.wizardForm && (
          <FormBuilderWizardForm data={formBuilder.wizardForm} form={form} />
        )}

        {formData && (
          <>
            <FormBuilderAnswers
              form={form}
              data={formData}
              listData={listForms}
              disabled={disableForm}
              onTextImageUpload={
                onFieldUpdate
                  ? async (upload) => {
                      if (!upload.response) return;

                      const data = await onFieldUpdate?.({
                        uploads: [upload.response.uuid],
                      });

                      if (data && upload.url) {
                        upload.url = upload.url.replace('/tmp/', '/uploads/');

                        if (upload.response) {
                          upload.response.url = upload.url;
                          upload.response.stated = true;
                        }

                        const currentUploads = form.getFieldValue('uploads');

                        form.setFieldValue(
                          'uploads',
                          currentUploads
                            ? [...currentUploads, upload]
                            : [upload]
                        );

                        return upload.url;
                      }
                    }
                  : undefined
              }
            />

            <FormItem
              data={{
                ...requestFixedFields.uploads,
                answer: request?.uploads,
              }}
            >
              <FieldUpload
                data={requestFixedFields.uploads}
                resource="request"
                resourceIds={request ? [request.uuid] : undefined}
                disabled={disableForm}
                showDownloadIcon={!!onFieldUpdate}
                onRemove={onFormUpdate}
                onRequest={async (upload) => {
                  try {
                    return await onFieldUpdate?.({ uploads: [upload.uuid] });
                  } catch (error) {
                    asyncErrorHandler(error);
                  }
                }}
              />
            </FormItem>

            {footer}
          </>
        )}
      </Form>
    </div>
  );
};

export default RequestForm;
