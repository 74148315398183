import React, {
  forwardRef,
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { message } from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import Lightbox, { LightboxProps } from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { fromUint8Array, toUint8Array } from 'js-base64';
import * as Y from 'yjs';
import { QuillBinding } from 'y-quill';
import AutoLinks from 'quill-auto-links';
import ImageUploader from 'quill-image-uploader';
import 'quill-mention';
import { UploadFileApi } from 'src/types';
import { isImgElement } from 'src/utils/guardTypes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import ClickableLink from './ClickableLink';
import CustomTheme from './CustomTheme';
import './index.style.scss';
import { debounceAsync } from 'src/utils/debounce';
import { AxiosResponse } from 'axios';

function convertFileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

const mentionGroupOrder = ['regular_user', 'contact', 'group'];

const debounceGetMention = debounceAsync<AxiosResponse>(
  (projectId: string | undefined, searchTerm: string) => {
    const url = projectId
      ? `${apiRoutes.BASE_URL}/projects/${projectId}/mentionables`
      : `${apiRoutes.BASE_URL}/mentions/mentionables`;

    return apiRequests.get(url, {
      search_term: searchTerm || undefined,
    });
  },
  400
);

Quill.register(
  {
    'modules/autoLinks': AutoLinks,
    'modules/imageUploader': ImageUploader,
    'themes/snow': CustomTheme,
    'formats/link': ClickableLink,
  },
  true
);

export interface RichTextEditorProps {
  id?: string;
  className?: string;
  defaultValue?: string;
  value?: string;
  projectId?: string;
  placeholder?: string;
  readonly?: boolean;
  noContainer?: boolean;
  includeMention?: boolean;
  includeToolbar?: boolean;
  showMoreButton?: boolean;
  imageAsData64?: boolean;
  mentionTypes?: ('regular_user' | 'freelancer' | 'contact' | 'group')[];
  scrollingContainer?: string | HTMLElement | undefined;
  collaborativeEditor?: {
    defaultValue?: string | null;
  };
  onImageUploader?: (upload: UploadFileApi) => Promise<void | string>;
  onImageModalChanged?: (open: boolean) => void;
  onShortEnterKey?: (range: any, context: any) => void;
  onChange?: ReactQuill.ReactQuillProps['onChange'];
  onLoadingImage?: (callback: (prev: number) => number) => void;
}

export interface RichTextEditorHandle {
  getQuill(): ReactQuill | undefined;
  getYjs(): string;
}

const RichTextEditor = forwardRef<RichTextEditorHandle, RichTextEditorProps>(
  (
    {
      id,
      className = '',
      defaultValue,
      value,
      projectId,
      placeholder,
      readonly = false,
      noContainer = false,
      includeMention = false,
      includeToolbar = true,
      showMoreButton = false,
      imageAsData64 = false,
      mentionTypes,
      scrollingContainer,
      collaborativeEditor,
      onImageUploader,
      onImageModalChanged,
      onShortEnterKey,
      onChange,
      onLoadingImage,
    },
    ref
  ) => {
    const quillRef = useRef<ReactQuill>();
    const yDoc = useRef<Y.Doc>();
    const projectRef = useRef(projectId);
    const onShortEnterKeyRef = useRef(onShortEnterKey);
    const mentionTypesRef = useRef(mentionTypes);

    const [openLightbox, setOpenLightbox] = useState(false);
    const [listLightbox, setListLightbox] = useState<LightboxProps['slides']>(
      []
    );
    const [indexLightbox, setIndexLightbox] = useState(0);
    const [showAll, setShowAll] = useState(false);
    const [hasLargeContent, setHasLargeContent] = useState(false);

    const editorHeight = quillRef.current?.getEditingArea()?.clientHeight;

    if (projectRef.current !== projectId) {
      projectRef.current = projectId;
    }

    if (mentionTypesRef.current !== mentionTypes) {
      mentionTypesRef.current = mentionTypes;
    }

    if (onShortEnterKeyRef.current !== onShortEnterKey) {
      onShortEnterKeyRef.current = onShortEnterKey;
    }

    const [modules] = useState({
      toolbar: [] as any,
      autoLinks: true,
      mention: undefined as any,
      keyboard: onShortEnterKey
        ? {
            bindings: {
              tab: {
                key: 13,
                shortKey: true,
                handler: (range: any, context: any) =>
                  onShortEnterKeyRef.current?.(range, context),
              },
            },
          }
        : undefined,
      imageUploader: {
        upload: async (file: File) => {
          if (!/^image\//.test(file.type)) {
            throw new Error('You could only upload images.');
          }

          if (imageAsData64) {
            return await convertFileToBase64(file);
          }

          if (onLoadingImage) {
            onLoadingImage((prev) => prev + 1);
          }

          try {
            const response = await apiRequests.uploadFile({
              file,
              type: 'in-message',
            });

            if (onImageUploader) {
              const newUrl = await onImageUploader({
                uid: response.uuid,
                name: file.name,
                status: 'done',
                url: response.url,
                size: file.size,
                lastModified: file.lastModified,
                lastModifiedDate: new Date(file.lastModified),
                type: file.type,
                percent: 100,
                originFileObj: {
                  ...file,
                  lastModifiedDate: new Date(file.lastModified),
                  uid: response.uuid,
                },
                response,
              });

              if (newUrl) {
                return newUrl;
              }
            }

            return response.url;
          } finally {
            onLoadingImage?.((prev) => prev - 1);
          }
        },
      },
    });

    if (!readonly) {
      if (includeToolbar) {
        modules.toolbar = [
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['code-block'],
          ['link', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ color: [] }, { background: [] }],
          ['clean'],
        ];
      }

      if (includeMention) {
        modules.mention = {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          defaultMenuOrientation: 'top',
          mentionDenotationChars: ['@'],
          source: async (
            searchTerm: string,
            renderList: (list: []) => void
          ) => {
            if (!projectRef.current) {
              message.warning('Select a project before you mention a user');
              renderList([]);
              return;
            }

            try {
              const res = await debounceGetMention(
                projectRef.current,
                searchTerm
              );

              const list: any[] = (
                mentionTypesRef.current
                  ? res.data.data.filter((item: any) =>
                      mentionTypesRef.current?.includes(item.type)
                    )
                  : res.data.data
              ).map((el: any) => ({
                value: el.name,
                id: el.uuid,
                type: el.type,
              }));

              list.sort((a, b) => {
                const typeA = mentionGroupOrder.indexOf(
                  a.type === 'freelancer' ? 'regular_user' : a.type
                );
                const typeB = mentionGroupOrder.indexOf(
                  b.type === 'freelancer' ? 'regular_user' : b.type
                );

                return typeA - typeB;
              });

              if (
                !mentionTypesRef.current ||
                mentionTypesRef.current.includes('regular_user') ||
                mentionTypesRef.current.includes('freelancer')
              ) {
                const firstUserIndex = list.findIndex(
                  (x) => x.type === 'regular_user' || x.type === 'freelancer'
                );

                if (firstUserIndex >= 0) {
                  list.splice(firstUserIndex, 0, {
                    value: 'Users',
                    disabled: true,
                    isTitle: true,
                  });
                }
              }

              if (
                !mentionTypesRef.current ||
                mentionTypesRef.current.includes('contact')
              ) {
                const firstContactIndex = list.findIndex(
                  (x) => x.type === 'contact'
                );

                if (firstContactIndex >= 0) {
                  list.splice(firstContactIndex, 0, {
                    value: 'Contacts',
                    disabled: true,
                    isTitle: true,
                  });
                }
              }

              if (
                !mentionTypesRef.current ||
                mentionTypesRef.current.includes('group')
              ) {
                const firstGroupIndex = list.findIndex(
                  (x) => x.type === 'group'
                );

                if (firstGroupIndex >= 0) {
                  list.splice(firstGroupIndex, 0, {
                    value: 'Groups',
                    disabled: true,
                    isTitle: true,
                  });
                }
              }

              renderList(list as []);
            } catch (error) {
              renderList([]);
              asyncErrorHandler(error);
            }
          },
        };
      }
    }

    const propValues: any = {};

    if (value) {
      propValues.value = value;
    }

    useEffect(() => {
      const quill = quillRef.current;

      if (!collaborativeEditor || !quill) {
        return;
      }

      yDoc.current = new Y.Doc();

      const binding = new QuillBinding(
        yDoc.current.getText('quill'),
        quill.getEditor()
      );

      if (collaborativeEditor.defaultValue) {
        Y.applyUpdate(
          yDoc.current,
          toUint8Array(collaborativeEditor.defaultValue)
        );
      }

      return () => {
        binding.destroy();
        yDoc.current?.destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOpenLightboxHandler: MouseEventHandler<HTMLDivElement> = (
      event
    ) => {
      const element = event.target;

      if (!isImgElement(element)) {
        return;
      }

      const listImages: LightboxProps['slides'] = [];

      const parser = new DOMParser();

      const doc = parser.parseFromString(
        defaultValue || value || '',
        'text/html'
      );

      const images = doc.querySelectorAll('img');

      images.forEach((image) => {
        listImages.push({ src: image.src });

        if (element.src === image.src) {
          setIndexLightbox(listImages.length - 1);
        }
      });

      onImageModalChanged?.(true);

      setListLightbox(listImages);

      setOpenLightbox(true);
    };

    useEffect(() => {
      const container = quillRef.current?.getEditingArea();

      if (!container) return () => {};

      const openLightboxHandler = (event: Event) => {
        const element = event.target;

        if (isImgElement(element) && quillRef.current) {
          const { ops } = quillRef.current.getEditor().getContents();

          const listImages: LightboxProps['slides'] = [];

          ops?.forEach((delta) => {
            if (delta.insert.image) {
              listImages.push({
                src: delta.insert.image,
              });
            }

            if (element.src === delta.insert.image) {
              setIndexLightbox(listImages.length - 1);
            }
          });

          onImageModalChanged?.(true);

          setListLightbox(listImages);

          setOpenLightbox(true);
        }
      };

      container.addEventListener('click', openLightboxHandler);

      return () => {
        container?.removeEventListener('click', openLightboxHandler);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
      if (showMoreButton && editorHeight && editorHeight > 150) {
        setHasLargeContent(true);
      }
    }, [showMoreButton, editorHeight]);

    useImperativeHandle(ref, () => ({
      getQuill() {
        return quillRef.current;
      },

      getYjs(): string {
        if (!yDoc.current) {
          return '';
        }

        return fromUint8Array(Y.encodeStateAsUpdate(yDoc.current));
      },
    }));

    return (
      <>
        {!readonly || collaborativeEditor ? (
          <div className="relative">
            <ReactQuill
              id={id}
              ref={quillRef}
              theme="snow"
              className={`${className}${!includeToolbar ? ' no-toolbar' : ''}${
                noContainer ? ' no-container' : ''
              }${
                showMoreButton && !showAll && hasLargeContent
                  ? ' quill-reduced-size'
                  : ''
              }${
                showMoreButton && hasLargeContent && showAll
                  ? ' quill-full-size'
                  : ''
              }`}
              modules={modules}
              scrollingContainer={scrollingContainer}
              placeholder={placeholder}
              defaultValue={!collaborativeEditor ? defaultValue : undefined}
              onChange={onChange}
              readOnly={readonly}
              {...propValues}
            />

            {showMoreButton && hasLargeContent && (
              <div
                className={`quill-content-show ${
                  !showAll ? 'quill-content-show--more' : ''
                }`}
              >
                <button
                  className="mx-auto block font-bold text-blue-700"
                  onClick={() => setShowAll(!showAll)}
                >
                  {!showAll ? 'Show more' : 'Show less'}
                </button>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`quill ${className} ${
              noContainer ? 'no-container' : ''
            }`}
          >
            <div className="ql-container ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: defaultValue || value || '',
                }}
                onClick={onOpenLightboxHandler}
              />
            </div>
          </div>
        )}

        <Lightbox
          open={openLightbox}
          index={indexLightbox}
          close={() => {
            onImageModalChanged?.(false);
            setOpenLightbox(false);
          }}
          plugins={[Counter, Thumbnails, Zoom]}
          counter={{ container: { style: { top: 'unset', bottom: 0 } } }}
          zoom={{ maxZoomPixelRatio: 4 }}
          carousel={{ finite: true }}
          slides={listLightbox}
        />
      </>
    );
  }
);

export default RichTextEditor;
