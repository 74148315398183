import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { sortRequestByStatus } from 'src/utils/arrayHelpers';
import {
  getDashboardRequests,
  setDashboardRequestsData,
  setDashboardRequestsError,
  setDashboardRequestsLoading,
} from './reducer';
import { RootState } from '../index';

const { get } = apiRequests;

function* getDashboardRequestSagaListener(action: any) {
  try {
    yield put(setDashboardRequestsLoading());

    const showAll: boolean = yield select(
      (globalStore: RootState) => globalStore.dashboardRequests.showCompleted
    );

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_REQUEST, {
      'filters[projects_requests.status]': [
        'draft',
        'new',
        'waiting_approval',
        'needs_attention',
        'approved',
        'in_progress',
        ...(showAll ? ['completed', 'cancelled'] : []),
      ],
      ...(action.payload ?? {}),
    });

    yield put(
      setDashboardRequestsData({
        ...res.data,
        data: sortRequestByStatus(res.data.data),
      })
    );
  } catch (error) {
    yield put(setDashboardRequestsError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardRequestsSaga(): Generator<StrictEffect> {
  yield takeLatest(getDashboardRequests.type, getDashboardRequestSagaListener);
}

export default dashboardRequestsSaga;
