import { FC } from 'react';
import { Empty, Form, Upload } from 'antd';
import UploadDragger from 'src/components/FileUpload/UploadDragger';
import IconUploadFile from 'src/components/IconUploadFile';
import ButtonDownloadAllFiles, {
  ButtonDownloadAllFilesProps,
} from 'src/components/ButtonDownloadAllFiles';
import { removeImage } from 'src/utils/stringHelpers';
import { UploadFileApi } from 'src/types';
import { FormBuilderFieldUpload } from 'src/types/formBuilder';

interface FieldUploadProps {
  id?: string;
  value?: UploadFileApi[];
  data: FormBuilderFieldUpload;
  disabled: boolean;
  showDownloadIcon?: boolean;
  resource?: ButtonDownloadAllFilesProps['resource'];
  resourceIds?: ButtonDownloadAllFilesProps['ids'];
  onChange?: (values: UploadFileApi[]) => void;
  onRequest?: (response: { url: string; uuid: string }) => Promise<unknown>;
  onRemove?: (upload: UploadFileApi) => void;
}

const FieldUpload: FC<FieldUploadProps> = ({
  id,
  value,
  resource,
  resourceIds,
  disabled,
  showDownloadIcon,
  onChange,
  onRequest,
  onRemove,
}) => {
  const form = Form.useFormInstance();

  if (disabled) {
    if (!value?.length) {
      return <Empty description="No files" />;
    }

    return (
      <>
        {resource && resourceIds && (
          <ButtonDownloadAllFiles resource={resource} ids={resourceIds} />
        )}

        <Upload
          listType="picture"
          className="grid"
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: false,
          }}
          fileList={value}
          iconRender={(upload: UploadFileApi) => (
            <IconUploadFile file={upload} />
          )}
          onPreview={(file) => {
            if (file.status === 'done') {
              window.open(
                !file.response?.stated
                  ? file.url
                  : `/file-viewer/${file.response.uuid}`,
                '_blank'
              );
            }
          }}
        />
      </>
    );
  }

  return (
    <UploadDragger
      containerId={id}
      maxSize="50MB"
      uploads={value ?? []}
      setUploads={onChange ?? (() => {})}
      showDownloadIcon={showDownloadIcon}
      showRemoveIcon
      onRequest={onRequest}
      onRemove={(upload) => {
        const answers = form.getFieldsValue();

        Object.keys(answers).forEach((key: any) => {
          const item = answers[key];

          if (
            typeof item === 'string' &&
            upload.response &&
            item.includes(upload.response.url)
          ) {
            form.setFieldsValue({
              [key]: removeImage(item, upload.response.url),
            });
          }
        });

        onRemove?.(upload);
      }}
    />
  );
};

export default FieldUpload;
