import { FC, useEffect, useState } from 'react';
import { Space, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import DownloadAsset from 'src/components/DownloadAsset';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

interface RequestViewTabDeliverablesProps {
  request: Record<string, any>;
  tabKey?: string;
  onChangeTab: (tab: string) => void;
}

const RequestViewTabDeliverables: FC<RequestViewTabDeliverablesProps> = ({
  request,
  tabKey,
  onChangeTab,
}) => {
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState<any[]>();

  useEffect(() => {
    const fetch = async () => {
      setFetching(true);

      try {
        const response = await apiRequests.get(
          `${apiRoutes.PROJECT_REQUEST}/${request.uuid}/deliverables`
        );

        setData(response.data.data);
      } catch (error) {
        asyncErrorHandler(error);
      } finally {
        setFetching(false);
      }
    };

    if (tabKey === '3') {
      fetch();
    }
  }, [request.uuid, tabKey]);

  return (
    <Table
      size="small"
      loading={fetching}
      dataSource={data}
      rowKey="uuid"
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (value, row) => (
            <Link to={`/file-viewer/${row.uuid}`} target="_blank">
              {value}
            </Link>
          ),
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'x',
          render: (_, row) => (
            <Space>
              <DownloadAsset uuid={row.uuid} name={row.name} />
              <Link
                to={`/requests/${request.uuid}?target_comment_id=${row.uploadable_id}`}
                onClick={() => onChangeTab('2')}
              >
                <EyeOutlined />
              </Link>
            </Space>
          ),
        },
      ]}
    />
  );
};

export default RequestViewTabDeliverables;
