import { useCallback, useEffect, useState } from 'react';
import { message, Result, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';
import Box from 'src/components/Box';
import { convertFormModelToFormBuilderForm } from 'src/components/Forms';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { FormBuilderData } from 'src/types/formBuilder';
import RequestUpdate from './RequestUpdate';
import RequestView from './RequestView';
import useWebSocket from 'src/utils/useWebSocket';
import { localRandomUuid } from 'src/utils/stringHelpers';

export const parseFormBuilder = (data: any): FormBuilderData => {
  const { description } = data;
  let questions: Record<string, any>[] = [];

  try {
    const desJson =
      typeof description === 'string' ? JSON.parse(description) : description;

    if (typeof desJson === 'object') {
      if (!Array.isArray(desJson)) {
        if ('inputs' in desJson.form) {
          desJson.form = desJson.form.inputs;
        }

        return desJson;
      }

      questions = desJson;
    }
  } catch (error) {
    questions = [
      {
        id: 'text',
        key: null,
        type: 'TextEditor',
        label: 'Description',
        answer: description,
        formType: 'Input',
        required: true,
        isQuestion: true,
      },
    ];
  }

  return convertFormModelToFormBuilderForm({
    uuid: data.uuid,
    title: data.title,
    description: data.description,
    questions,
  });
};

export const fetchRequest = async (
  uuid: string,
  params?: Record<string, any>
) => {
  const response = await apiRequests.get(
    `${apiRoutes.PROJECT_REQUEST}/${uuid}`,
    params
  );

  return response.data.data;
};

const RequestViewPage = () => {
  const [fetching, setFetching] = useState(true);
  const [request, setRequest] = useState<any>();
  const [formBuilder, setFormBuilder] = useState<FormBuilderData>();
  const [resetFormTrigger, setResetFormTrigger] = useState(0);

  const { uuid } = useParams<{ uuid: string }>();

  const isDraft = request?.status === 'draft';

  const saveRequest = async (values: any) => {
    values.ref_token = localRandomUuid;

    const response = await apiRequests.put(
      `${apiRoutes.PROJECT_REQUEST}/${request.uuid}?with=uploads`,
      values
    );

    const { data } = response.data;

    if (values.status === 'new') {
      message.success('Request submitted');
    }

    if (values.status === 'approved') {
      message.success('Request approved');
    }

    if (values.status === 'cancelled') {
      message.success('Request cancelled');
    }

    setFormBuilder(parseFormBuilder(data));

    setRequest(data);

    return data;
  };

  const fetchData = useCallback(async () => {
    if (!uuid) return;

    try {
      const data = await fetchRequest(uuid, {
        with: 'uploads',
      });

      setFormBuilder(parseFormBuilder(data));

      setRequest(data);
    } catch (error) {
      asyncErrorHandler(error);
    }
  }, [uuid]);

  useEffect(() => {
    setFetching(true);

    fetchData().finally(() => setFetching(false));
  }, [fetchData]);

  useWebSocket({
    channelName: 'model.changes',
    listen: {
      event: '.app.models.projects.request',
      callback: (event: any) => {
        if (
          event.action !== 'create' &&
          event.id === request?.uuid &&
          event.payload.ref_token !== localRandomUuid
        ) {
          fetchData()
            .then(() => {
              setResetFormTrigger((prev) => prev + 1);
            })
            .catch(() => {});
        }
      },
    },
  });

  if (!fetching && !request) {
    return (
      <Box>
        <Result
          status="404"
          subTitle="Sorry, the page you visited does not exist."
          title="404"
        />
      </Box>
    );
  }

  if (fetching) {
    return (
      <Box>
        <Skeleton style={{ margin: '12px 0' }} />
      </Box>
    );
  }

  if (!formBuilder) {
    return null;
  }

  if (isDraft) {
    return (
      <RequestUpdate
        request={request}
        formBuilder={formBuilder}
        resetFormTrigger={resetFormTrigger}
        saveRequest={saveRequest}
      />
    );
  }

  return (
    <RequestView
      request={request}
      formBuilder={formBuilder}
      resetFormTrigger={resetFormTrigger}
      onUpdate={saveRequest}
    />
  );
};

export default RequestViewPage;
