import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, message, Result, Skeleton } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import Box from 'src/components/Box';
import { FormBuilderData, FormBuilderFormData } from 'src/types/formBuilder';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import listFormsData from './listForms.json';
import RequestForm from './RequestForm';
import { fetchRequest, parseFormBuilder } from './RequestViewPage';
import useRequestFormActions from './useRequestFormActions';
import FormButtons from 'src/components/FormBuilder/FormButtons';

const listForms = listFormsData as FormBuilderData[];

const parseAnswerToFormBuilder = (
  duplicateForm: FormBuilderFormData[],
  targetForm: FormBuilderFormData[]
): FormBuilderFormData[] => {
  return targetForm.map<FormBuilderFormData>((item) => {
    if (item.type === 'formGroup') {
      const formGroup =
        item.fields ?? listForms.find((x) => item.formId === x.id)?.form;

      const duplicateFormGroup = duplicateForm.find(
        (sub) =>
          sub.type === 'formGroup' &&
          item.type === sub.type &&
          sub.formId === item.formId
      );

      if (
        !duplicateFormGroup ||
        !formGroup ||
        !('fields' in duplicateFormGroup) ||
        !duplicateFormGroup.fields
      ) {
        return { ...item };
      }

      return {
        ...item,
        fields: parseAnswerToFormBuilder(duplicateFormGroup.fields, formGroup),
      };
    }

    const question =
      'name' in item
        ? duplicateForm.find(
            (sub) =>
              'name' in sub && item.type === sub.type && sub.name === item.name
          )
        : undefined;

    return {
      ...item,
      answer: question && 'answer' in question ? question.answer : undefined,
    } as FormBuilderFormData;
  });
};

const RequestCreateDuplicatePage = () => {
  const [form] = Form.useForm();
  const [fetching, setFetching] = useState(true);
  const [request, setRequest] = useState<any>();
  const [formBuilder, setFormBuilder] = useState<FormBuilderData>();

  const { uuid } = useParams();
  const navigate = useNavigate();

  const saveRequest = async (values: any) => {
    const response = await apiRequests.post(apiRoutes.PROJECT_REQUEST, values);

    message.success('Request created!');

    navigate(`/requests/${response.data.data.uuid}`);

    return response.data.data;
  };

  const fetchData = useCallback(async () => {
    if (!uuid) return;

    try {
      const data = await fetchRequest(uuid, {
        with: 'uploads',
      });

      data.target_date = undefined;

      setRequest(data);

      const requestFormBuilder = parseFormBuilder(data);

      const currentFormBuilder = cloneDeep(
        listForms.find((item) => item.id === requestFormBuilder.id)
      );

      if (currentFormBuilder) {
        if (currentFormBuilder.form && requestFormBuilder.form) {
          currentFormBuilder.form = parseAnswerToFormBuilder(
            requestFormBuilder.form,
            currentFormBuilder.form
          );
        }

        if (currentFormBuilder.wizardForm && requestFormBuilder.wizardForm) {
          currentFormBuilder.wizardForm = currentFormBuilder.wizardForm.map(
            (item) => {
              const duplicateWizardForm = requestFormBuilder.wizardForm?.find(
                (sub) => sub.id === item.id
              );

              if (duplicateWizardForm) {
                item.form = parseAnswerToFormBuilder(
                  duplicateWizardForm.form,
                  item.form
                );
              }

              return item;
            }
          );
        }

        setFormBuilder(currentFormBuilder);
      }
    } catch (error) {
      asyncErrorHandler(error);
    }
  }, [uuid]);

  const { saving, onDraft, onSubmit } = useRequestFormActions({
    form,
    formBuilder,
    onDraft: saveRequest,
    onSubmit: saveRequest,
  });

  useEffect(() => {
    setFetching(true);

    fetchData().finally(() => setFetching(false));
  }, [fetchData]);

  if (!fetching && !request) {
    return (
      <Box>
        <Result
          title="404"
          status="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </Box>
    );
  }

  if (!fetching && !formBuilder) {
    return (
      <Box>
        <Result
          title="404"
          status="404"
          subTitle="Sorry, this request is not avaiable anymore."
        />
      </Box>
    );
  }

  if (fetching) {
    return (
      <Box>
        <Skeleton style={{ margin: '12px 0' }} />
      </Box>
    );
  }

  if (!formBuilder) {
    return null;
  }

  return (
    <Box>
      <RequestForm
        form={form}
        formBuilder={formBuilder}
        request={request}
        saving={saving}
        footer={
          <FormButtons
            form={form}
            saving={saving}
            onDraft={onDraft}
            onSubmit={onSubmit}
            showSavingLoading
          />
        }
        header
        confirmBeforeRedirect
      />
    </Box>
  );
};

export default RequestCreateDuplicatePage;
