import * as Sentry from '@sentry/react';
import ENVIRONMENT from './utils/environments';

Sentry.init({
  dsn: ENVIRONMENT.SENTRY_DNS,
  environment: ENVIRONMENT.SENTRY_ENVIRONMENT,
  release: ENVIRONMENT.SENTRY_RELEASE,
  ignoreErrors: [
    // Last pass error, on safari https://github.com/WordPress/openverse/issues/524
    'Non-Error promise rejection captured with value: Not implemented on this platform',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:simulateEvent, ParamCount:1',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:update, ParamCount:4',
    // Last pass error
    'ResizeObserver loop completed with undelivered notifications.',
    'timeout exceeded',
    'Network Error',
    'AxiosError',
  ],
  denyUrls: [
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /^webkit-masked-url:\/\/hidden/i,
    /^file:\/\//i,
    /.*\/node_modules\/lib0\/websocket\.js/i,
    /www\.clarity\.ms\/s\/.*\/clarity\.js/i,
  ],
  beforeSend(event) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

export function setSentryUser(user: any) {
  Sentry.setUser(
    user?.uuid
      ? {
          id: user.uuid,
          name: user.name,
          email: user.email,
        }
      : null
  );
}
