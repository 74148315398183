import { cloneElement, FC, ReactNode, useRef } from 'react';
import { message, Upload, UploadProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { FiUpload } from 'react-icons/fi';
import IconUploadFile from 'src/components/IconUploadFile';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { formatBytes } from 'src/utils/stringHelpers';
import { UploadFileApi } from 'src/types';
import ButtonDownloadAllFiles, {
  ButtonDownloadAllFilesProps,
} from '../ButtonDownloadAllFiles';

const { Dragger } = Upload;

interface UploadItemProps {
  description: ReactNode;
  children: ReactNode;
  file: UploadFileApi;
}

const UploadItem: FC<UploadItemProps> = ({ description, children, file }) => {
  return (
    <div className="text-gray-400">
      {description}
      <div className="whitespace-nowrap px-2 text-xs">
        <span>{formatBytes(file.size ?? 0)} - </span>
        {children}
      </div>
    </div>
  );
};

interface UploadDraggerProps {
  containerId?: string;
  setUploads: (file: UploadFileApi[]) => void;
  uploads: UploadFileApi[];
  maxSize?: string;
  resource?: ButtonDownloadAllFilesProps['resource'];
  resourceIds?: ButtonDownloadAllFilesProps['ids'];
  showRemoveIcon?: boolean;
  showDownloadIcon?: boolean;
  setLoading?: (loading: boolean) => void;
  onRemove?: (file: UploadFileApi) => void;
  onRequest?: (response: { url: string; uuid: string }) => Promise<unknown>;
  children?: ReactNode;
}

const UploadDragger: FC<UploadDraggerProps> = ({
  containerId,
  uploads,
  maxSize,
  setUploads,
  resource,
  resourceIds,
  showDownloadIcon = false,
  showRemoveIcon = false,
  setLoading,
  onRemove: onRemoveParent,
  onRequest,
  children,
}) => {
  const filesDownloadingRef = useRef<string[]>([]);

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, onError, file } = options;

    const abort = new AbortController();

    const current = uploads.find((item) => item.uid === (file as RcFile).uid);

    if (current) current.abortController = abort;

    try {
      const response = await apiRequests.uploadFile({
        file: file as File,
        type: 'attachment',
        abortController: abort,
      });

      if (onRequest) {
        const sentToApi: any = await onRequest(response);

        if (sentToApi) {
          response.url = response.url.replace('/tmp/', '/uploads/');
          response.stated = true;
        }
      }

      if (onSuccess) onSuccess(response);
    } catch (err) {
      if (onError)
        onError({
          status: 500,
          method: 'PUT',
          url: 'tes',
          name: 'eeee',
          message: 'tess',
        });
    }
  };

  const onChange = ({ file, fileList }: UploadChangeParam<UploadFileApi>) => {
    const hasLoading = fileList.find((item) => item.status === 'uploading');

    setLoading?.(!!hasLoading);

    if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }

    if (file.status === 'done' && file.response?.url) {
      file.url = file.response.url;
    }

    setUploads(fileList);
  };

  const onRemove: UploadProps['onRemove'] = (upload) => {
    if (onRemoveParent) onRemoveParent(upload);

    if (upload.response?.stated) {
      try {
        apiRequests.delete(`${apiRoutes.UPLOADS}/${upload.response.uuid}`);
      } catch (error) {
        asyncErrorHandler(error);
      }
    }
  };

  const onDownload: UploadProps['onDownload'] = (upload: UploadFileApi) => {
    const uuid = upload.response?.uuid;

    if (
      !uuid ||
      !upload.response?.stated ||
      filesDownloadingRef.current.includes(upload.uid)
    ) {
      window.open(upload.url, '_blank');
      return;
    }

    filesDownloadingRef.current.push(uuid);

    apiRequests
      .get(`${apiRoutes.UPLOADS}/${uuid}/download`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        link.download = upload.name;
        link.click();
      })
      .catch((error) => {
        asyncErrorHandler(error);
      })
      .finally(() => {
        const index = filesDownloadingRef.current.indexOf(uuid);

        if (index > -1) {
          filesDownloadingRef.current.splice(index, 1);
        }
      });
  };

  return (
    <>
      <Dragger
        className={`grid w-full ${!uploads.length ? 'no-files' : ''} ${
          children ? 'clean-drag-area' : ''
        }`}
        customRequest={customRequest}
        onDownload={onDownload}
        onChange={onChange}
        onRemove={onRemove}
        onPreview={(file) => {
          if (file.status === 'done') {
            window.open(
              !file.response?.stated
                ? file.url
                : `/file-viewer/${file.response.uuid}`,
              '_blank'
            );
          }
        }}
        listType="picture"
        fileList={uploads}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon,
          showRemoveIcon,
          downloadIcon: 'Download',
        }}
        multiple
        itemRender={(element, file) => {
          if (!Array.isArray(element.props.children)) {
            return element;
          }

          const [thumbnail, item, ...rest] = element.props.children;
          const [description, ...restItem] = item;

          return cloneElement(element, {
            key: file.uid,
            children: [
              thumbnail,
              <UploadItem
                description={description}
                children={restItem}
                file={file}
              />,
              ...rest,
            ],
          });
        }}
        iconRender={(upload: UploadFileApi) => <IconUploadFile file={upload} />}
      >
        {children || (
          <div id={containerId}>
            <FiUpload className="inline text-xl text-blue-700" />

            <p className="mt-1 font-bold text-blue-700">
              Upload files {maxSize ? `(${maxSize} max file size)` : ''}
            </p>

            <p className="text-xs text-[#00000073]">
              Click or drag file to this area to upload
            </p>
          </div>
        )}
      </Dragger>

      {resource && resourceIds && uploads.length > 0 && (
        <ButtonDownloadAllFiles resource={resource} ids={resourceIds} />
      )}
    </>
  );
};

export default UploadDragger;
