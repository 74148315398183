import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegFolderOpen, FaRegListAlt } from 'react-icons/fa';
import { PiDownloadSimpleBold } from 'react-icons/pi';
import Box from 'src/components/Box';
import { RootState } from 'src/store';
import { Button, Collapse, Empty, List, message, Spin } from 'antd';
import DownloadAsset from 'src/components/DownloadAsset';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const ProjectAssets: FC = () => {
  const projectRetainer = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const [files, setFiles] = useState<Record<string, any> | null>(null);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [downloadProjectFiles, setDownloadProjectFiles] = useState(false);
  const [downloadRequestFiles, setDownloadRequestFiles] = useState(false);

  const projectRetainerLoading =
    projectRetainer.loading && !projectRetainer.data;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingFiles(true);
        const res = await apiRequests.get(
          `${apiRoutes.PROJECTS}/${projectRetainer.data?.uuid}/files`
        );

        setFiles(res.data.data);
      } catch (error) {
        asyncErrorHandler(error);
      } finally {
        setLoadingFiles(false);
      }
    };

    if (projectRetainer.data?.uuid) {
      fetchData();
    }
  }, [projectRetainer.data?.uuid]);

  const downloadAllAssets = async (data: Record<string, any>) => {
    try {
      const res = await apiRequests.post(`${apiRoutes.MASS_DOWNLOAD}`, data);
      message.success(res?.data?.message);
    } catch (error) {
      asyncErrorHandler(error);
    }
  };

  return (
    <>
      <div className="mb-8 flex w-full flex-col gap-2">
        <div className="flex gap-2 font-bold text-blue-600">
          <FaRegFolderOpen className="text-2xl" />

          <span className="flex-auto">Assets</span>

          <Button
            type="primary"
            size="small"
            disabled={
              !(files?.project_files?.length > 0) || downloadProjectFiles
            }
            onClick={() => {
              setDownloadProjectFiles(true);

              downloadAllAssets({
                full_download: true,
                file_ids: files?.project_files?.map((item: any) => item.uuid),
              }).finally(() => setDownloadProjectFiles(false));
            }}
          >
            <span className="pr-2 text-xs">Download all</span>

            <PiDownloadSimpleBold className="inline-block align-sub text-base" />
          </Button>
        </div>

        <Box className="max-h-[320px] overflow-auto">
          <List
            dataSource={files?.project_files}
            loading={loadingFiles || projectRetainerLoading}
            locale={{
              emptyText: (
                <Empty
                  description={<span className="text-gray-400">No assets</span>}
                />
              ),
            }}
            renderItem={(asset: any) => (
              <List.Item
                className="!py-1"
                actions={[
                  <DownloadAsset uuid={asset.uuid} name={asset.name} />,
                ]}
              >
                {asset.name}
              </List.Item>
            )}
          />
        </Box>
      </div>

      <div className="flex w-full flex-col gap-2">
        <div className="flex gap-2 font-bold text-blue-600">
          <FaRegListAlt className="text-2xl" />

          <span className="flex-auto">Request attachments</span>

          <Button
            type="primary"
            size="small"
            disabled={
              !(files?.requests_files?.length > 0) || downloadRequestFiles
            }
            onClick={() => {
              setDownloadRequestFiles(true);

              downloadAllAssets({
                full_download: true,
                file_ids: files?.requests_files?.reduce(
                  (acc: string[], request: any) => [
                    ...acc,
                    ...request.uploads.map((file: any) => file.uuid),
                  ],
                  []
                ),
              }).finally(() => setDownloadRequestFiles(false));
            }}
          >
            <span className="pr-2 text-xs">Download all</span>

            <PiDownloadSimpleBold className="inline-block align-sub text-base" />
          </Button>
        </div>

        <Box className="max-h-[320px] overflow-auto">
          <div className="flex min-h-[50px] w-full justify-center">
            <Spin
              wrapperClassName="w-full"
              spinning={loadingFiles || projectRetainerLoading}
            >
              <Collapse
                defaultActiveKey={[0]}
                className="ant-collapse-list-gray ant-collapse-header-padding-none"
                expandIconPosition="end"
                size="small"
                ghost
                accordion
              >
                {files?.requests_files?.map((request: any) => (
                  <Collapse.Panel key={request.id} header={request.title}>
                    <List
                      size="default"
                      dataSource={request.uploads}
                      renderItem={(asset: any) => (
                        <List.Item
                          className="!py-1"
                          actions={[
                            <DownloadAsset
                              uuid={asset.uuid}
                              name={asset.name}
                            />,
                          ]}
                        >
                          {asset.name}
                        </List.Item>
                      )}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>

              {(!loadingFiles && !projectRetainerLoading && !files) ||
              files?.requests_files?.length === 0 ? (
                <Empty
                  className="p-4"
                  description={
                    <span className="text-gray-400">
                      No request attachments
                    </span>
                  }
                />
              ) : undefined}
            </Spin>
          </div>
        </Box>
      </div>
    </>
  );
};

export default ProjectAssets;
