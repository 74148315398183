import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Typography } from 'antd';
import Box from 'src/components/Box';

interface User {
  name: string;
  role: string;
  title: string;
  description: string;
  link: string;
  imageUrl: string;
}

const users: User[] = [
  {
    name: 'Andrew Sierra',
    role: 'Video Production Manager',
    title: 'Video Production',
    description:
      'Are you looking to create captivating scripts, deliver impactful video content, and craft unforgettable stories?',
    imageUrl: '/assets/Andrew.png',
    link: 'https://calendar.app.google/VC5vz8EgAWs3STsM6',
  },
  {
    name: 'Christopher Savage',
    role: 'Founder / CEO',
    title: 'Marketing Strategy',
    description:
      'Are you looking for expert marketing consultation to create standout strategies that outshine your competition?',
    link: 'https://calendar.app.google/rw4nYKqsAHKoFUQWA',
    imageUrl: '/assets/Chris.png',
  },
  {
    name: 'Francisco Cordeiro',
    role: 'Ad Manager',
    title: 'Advertising',
    description:
      'Are you looking to launch powerful PPC campaigns that drive real results on social media, Google, and more?',
    link: 'https://calendar.app.google/yYJzf2u5JnaN8NnC7',
    imageUrl: '/assets/Francisco.png',
  },
  {
    name: 'Santiago Sanches',
    role: 'CTO',
    title: 'Web & Graphics',
    description:
      'Looking for eye-catching graphics, engaging websites, or streamlined development to power your digital growth?',
    link: 'https://calendar.app.google/vo11updE8Nj9TKvR7',
    imageUrl: '/assets/Santi.png',
  },
];

interface BookingProps {
  showHomeButton?: boolean;
}

const Booking: React.FC<BookingProps> = ({ showHomeButton = false }) => {
  return (
    <Box
      className={`mx-auto max-w-7xl ${
        showHomeButton ? 'px-10 pb-20 pt-10' : 'p-6'
      }`}
      noPadding
    >
      {showHomeButton && (
        <div className="mb-8">
          <Link
            to="/"
            className="rounded-full bg-[#0044CC] px-6 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go to Lionshare
          </Link>
        </div>
      )}

      <h1 className="mb-8 text-4xl font-semibold md:w-1/2">
        Book a call with one of our top experts
      </h1>

      <div className="grid gap-8 md:grid-cols-2">
        {users.map((item) => (
          <div className="flex flex-col justify-between rounded-xl border border-[#0044cc33] p-8">
            <div>
              <h4 className="mb-3 text-xl font-semibold leading-none">
                {item.title}
              </h4>

              <Typography.Paragraph className="!mb-5" type="secondary">
                {item.description}
              </Typography.Paragraph>
            </div>

            <div>
              <div className="mb-3">
                <Button href={item.link} target="_blank" type="primary">
                  Book a call
                </Button>
              </div>

              <div className="flex items-center gap-2">
                <Avatar src={item.imageUrl} size="default" />

                <div className="flex flex-col">
                  <Typography.Text className="!text-sm font-semibold">
                    {item.name}
                  </Typography.Text>

                  <Typography.Text className="!text-xs" type="secondary">
                    {item.role}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default Booking;
